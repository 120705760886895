/***

Sass Variables

Enter all variables used across stylesheets here.
These could include fonts, colors, measurements, etc.

***/

// COLORS
$black: #000000;
$white: #ffffff;
$gray_5:  lighten($black, 95%);
$gray_10: lighten($black, 90%);
$gray_15: lighten($black, 85%);
$gray_20: lighten($black, 80%);
$gray_25: lighten($black, 75%);
$gray_30: lighten($black, 70%);
$gray_35: lighten($black, 65%);
$gray_40: lighten($black, 60%);
$gray_45: lighten($black, 55%);
$gray_50: lighten($black, 50%);
$gray_55: lighten($black, 45%);
$gray_60: lighten($black, 40%);
$gray_65: lighten($black, 35%);
$gray_70: lighten($black, 30%);
$gray_75: lighten($black, 25%);
$gray_80: lighten($black, 20%);
$gray_85: lighten($black, 15%);
$gray_90: lighten($black, 10%);
$gray_95: lighten($black, 5%);

// BRAND COLORS
$green: #79863C;
$blue: #00263E;
$orange: #e67e22;
$gray: #A0A2A3;
$light_gray: #EFEFEF;
$medium_gray: #4D4D4D;
$dark_gray: #3A3F3F;

// MEASUREMENTS
$fixed_header_height_small: 60px;
$fixed_header_height_large: 80px;

// TYPOGRAPHY
$font-primary: 'Teko', sans-serif;
$font-secondary: 'Fira Sans', sans-serif;
